(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["MUI"] = factory();
	else
		root["MUI"] = root["MUI"] || {}, root["MUI"]["v5"] = root["MUI"]["v5"] || {}, root["MUI"]["v5"]["icons-material"] = factory();
})(self, () => {
return 